import React, { Fragment } from "react";

export default () => (
  <Fragment>
    Brandon Conway
    <br />
    <small>
      <em>
        I enjoy learning about and writing code in many programming languages
      </em>
    </small>
  </Fragment>
);
